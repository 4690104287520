import React, { useState, useEffect } from 'react'
import { Block, Button, Container, Layout } from '@components'
import Jimp from 'jimp/es'
import overlay from '../../static/for-canterbury-v3.png'
import style from './customise.mod.scss'

const CustomisePage = () => {
  const [image, setImage] = useState({ preview: '', raw: '' })
  const [loading, setLoading] = useState(false)
  const [line1, setLine1] = useState('')
  const [line2, setLine2] = useState('')
  const [canDownload, setCanDownload] = useState(false)

  const dimensionsA4 = { x: 2480, y: 3508 }

  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      })
    }
  }

  function exifRotate(img) {
    const exif = img._exif
    if (exif && exif.tags && exif.tags.Orientation) {
      switch (img._exif.tags.Orientation) {
        case 1: // Horizontal (normal)
          // do nothing
          break
        case 2: // Mirror horizontal
          img.mirror(true, false)
          break
        case 3: // Rotate 180
          img.rotate(180, false)
          break
        case 4: // Mirror vertical
          img.mirror(false, true)
          break
        case 5: // Mirror horizontal and rotate 270 CW
          img.rotate(-90, false).mirror(true, false)
          break
        case 6: // Rotate 90 CW
          img.rotate(-90, false)
          break
        case 7: // Mirror horizontal and rotate 90 CW
          img.rotate(90, false).mirror(true, false)
          break
        case 8: // Rotate 270 CW
          img.rotate(-270, false)
          break
        default:
          break
      }
    }

    return img
  }

  const handleManipulation = () => {
    setCanDownload(false)
    if (!image.preview) return
    var img = document.getElementById('img')
    setLoading(true)
    Jimp.read(img.getAttribute('src'))
      .then(image_j => {
        Jimp.read(overlay).then(overlay_j => {
          Jimp.loadFont('/fonts/SharpSans-Bold.ttf.fnt').then(font => {
            Jimp.loadFont('/fonts/SharpSans-Extrabold.ttf.fnt').then(
              fontLarge => {
                Jimp.create(dimensionsA4.x, dimensionsA4.y, 0).then(
                  fontCanvas => {
                    // resize image to match overlay first
                    // also any translation would happen here - we might want to move image to the top of the overlay
                    image_j.cover(dimensionsA4.x, dimensionsA4.y)
                    image_j.composite(overlay_j, 0, 0)

                    // Add Text Here

                    if (!line2) {
                      if (line1.length < 9) {
                        fontCanvas.print(
                          fontLarge,
                          820,
                          2025,
                          {
                            text: line1
                          },
                          1350
                        )
                      } else {
                        fontCanvas.print(
                          font,
                          780,
                          2050,
                          {
                            text: line1
                          },
                          1350
                        )
                      }

                      //fontCanvas.color([{ apply: 'xor', params: ['#00ff00'] }]);
                      fontCanvas.rotate(10.5)
                      //fontCanvas.scale(2)
                      image_j.composite(fontCanvas, 0, 0)
                    }

                    if (line1 && line2) {
                      if (line1.length < 9 && line2.length < 9) {
                        fontCanvas.print(
                          fontLarge,
                          820,
                          1910,
                          {
                            text: line1
                          },
                          1350
                        )
                        fontCanvas.print(
                          fontLarge,
                          820,
                          2135,
                          {
                            text: line2
                          },
                          1350
                        )
                      } else {
                        fontCanvas.print(
                          font,
                          780,
                          1950,
                          {
                            text: line1
                          },
                          1350
                        )
                        fontCanvas.print(
                          font,
                          780,
                          2150,
                          {
                            text: line2
                          },
                          1350
                        )
                      }

                      //fontCanvas.color([{ apply: 'xor', params: ['#00ff00'] }]);
                      fontCanvas.rotate(10.5)
                      //fontCanvas.scale(2)
                      image_j.composite(fontCanvas, 0, 0)
                    }

                    exifRotate(image_j)

                    image_j.getBase64(Jimp.MIME_JPEG, function(err, src) {
                      var img = document.getElementById('img')
                      img.setAttribute('src', src)
                      setLoading(false)
                      setCanDownload(true)
                    })
                  }
                )
              }
            )
          })
        })
      })
      .catch(error => {
        setLoading(false)
        console.log(`Error loading image -> ${error}`)
      })
  }

  const doPdfDownload = () => {
    // triggered by clicking button
  }

  const doImgDownload = () => {
    var a = document.createElement('a')
    a.href = document.getElementById('img').src
    a.download = 'poster.png'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const classes = [style.customise]

  image.preview && classes.push(`${style.customise}--preview`)
  console.log(classes)

  return (
    <Layout>
      <div className={[...classes].join(' ')}>
        <Block paddingTop="double" paddingBottom="double">
          <Container>
            <Block>
              <h1>Create your custom content</h1>
            </Block>
            <div className={style.customise__wrapper}>
              {/* ------------------------------------------------ */}
              <div className={style.customise__upload}>
                <h4>1. Enter custom text</h4>
                <p>Limited to 14 characters per line</p>
                <label className={style.customise__line1}>
                  Line One (required)
                  <input
                    type="text"
                    value={line1}
                    onChange={e => setLine1(e.target.value)}
                    required
                    maxlength="14"
                  />
                </label>
                <label className={style.customise__line2}>
                  Line Two (optional)
                  <input
                    type="text"
                    value={line2}
                    maxlength="14"
                    onChange={e => setLine2(e.target.value)}
                  />
                </label>

                <div className={style.customise__photo}>
                  <h4>2. Upload your photo</h4>
                  <p>
                    Ideally this should be a portrait image, the focal point
                    will be on the top third of the image.
                  </p>
                  <label className={style.customise__upload_button}>
                    <p>{image.preview ? 'Change Image' : 'Upload Image'}</p>
                    <input
                      type="file"
                      id="upload-button"
                      onChange={handleChange}
                    />
                  </label>
                </div>

                <div className={style.customise__generate}>
                  <h4>3. Generate Preview</h4>
                  <p>
                    This will add the custom text and the template to your
                    image.
                    <br /> If you want to change the image after the Preview
                    simply go to Step 2.
                  </p>
                  <Button
                    disabled={line1 === '' || !image.preview ? true : false}
                    centre
                    type={'button'}
                    onClick={handleManipulation}
                  >
                    Create Artwork
                  </Button>
                </div>
              </div>

              {/* ------------------------------------------------ */}

              <div className={style.customise__preview}>
                <h4>{canDownload ? 'Complete' : 'Preview'}</h4>
                <div className={style.customise__image}>
                  <div className={style.customise__pad}>
                    {image.preview && (
                      <div className={loading && 'loading'}>
                        <img id="img" src={image.preview} alt="dummy" />
                      </div>
                    )}
                    {!canDownload && (
                      <img
                        className={style.customise__placeholder}
                        src={overlay}
                        alt="dummy"
                      />
                    )}
                  </div>
                  {loading && (
                    <div className={style.customise__loading}>
                      <h4>
                        Generating
                        <br />
                        Artwork
                      </h4>
                      <p>This could take a moment</p>
                    </div>
                  )}
                </div>

                {canDownload && (
                  <>
                    <div className={style.customise__download}>
                      <Button
                        id="download-button"
                        type={'button'}
                        onClick={doImgDownload}
                        disabled={!canDownload}
                      >
                        Download
                      </Button>
                    </div>
                    <div className={style.customise__download_mobile}>
                      <h5>Ready to save</h5>
                      <p>Tap and hold image to save</p>
                    </div>
                  </>
                )}
              </div>
              {/* ------------------------------------------------ */}

              {/* ------------------------------------------------ */}
            </div>
          </Container>
        </Block>
      </div>
    </Layout>
  )
}

export default CustomisePage
